
import CompanyData from "@/components/CompanyData.vue";
import * as Api from "@/types/graphql";
import { Component, Vue } from "vue-property-decorator";
import { Action, State } from "vuex-class";
import { State as StateStore } from "../store";

@Component({
  components: {
    CompanyData
  }
})
export default class CompanyView extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @Action getUser;

  companyGuid = null

  async mounted() {
    await this.getUser();
    this.companyGuid = this.user.company_guid
  }
  
}
